<template>
  <div>
    <el-dialog title="查看子订单" width="85%" :visible.sync="dialogTableVisible" @close="closeDialog">
      <el-table :data="packageData.data" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
        <el-table-column label="序号" width="70">
          <template slot-scope="scope">
            <span>{{scope.$index+(packageData.current_page - 1) * packageData.per_page + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单ID" prop="order_id" width="70"></el-table-column>
        <el-table-column label="第三方订单号" prop="third_order_no" width="200"></el-table-column>
        <el-table-column label="姓名" prop="receive_name" width="90"></el-table-column>
        <el-table-column label="手机号" prop="receive_phone" width="120"></el-table-column>
        <el-table-column label="收货地址" width="320">
          <template slot-scope="scope">
            <span>{{scope.row.receive_province + scope.row.receive_city + scope.row.receive_town + scope.row.receive_address}}</span>
          </template>
        </el-table-column>
        <el-table-column label="快递公司" prop="deliver_name"></el-table-column>
        <el-table-column label="快递单号" width="200">
          <template slot-scope="scope">
	    <div v-if="scope.row.waybill_code === '***'" class="noway">
	      ***<br>
	      <span class="nowaybill">加客服[商家认证]</span>
	    </div>
	    <div v-else>
              <span>{{ scope.row.waybill_code }} </span>
	      <img src="~assets/icons/copy4.png" class="cpImg" v-if="scope.row.waybill_code !== ''" v-clipboard:copy="scope.row.waybill_code" v-clipboard:success="onCopy" v-clipboard:error="onError"  />
	    </div>
          </template>
	</el-table-column>
        <el-table-column label="发货时间" >
          <template slot-scope="scope"><span>{{timeConversion(scope.row.createtime)}}</span></template>
        </el-table-column>
<!--        <el-table-column label="问题描述"></el-table-column>-->
        <el-table-column label="状态">
          <template slot-scope="scope">
       <span style="color:red">{{scope.row.order_status_text}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="packageData.per_page"
          layout="prev, pager, next"
          :current-page="packageData.current_page"
          :total="packageData.total">
        </el-pagination>
      </div>
      <span slot="footer">
        <el-button v-if="!showDownload" type="primary" size="small">下载</el-button>
        <a class="download" v-else :href="url+'/api/order/excel_order?id=' + id"><span>下载</span></a>
        <el-button @click="dialogTableVisible = false" size="small">取消</el-button>
        <el-button @click="dialogTableVisible = false" type="primary" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { timeConversion } from 'utils/utils'
export default {
  data () {
    return {
      dialogTableVisible: false,
      url: ''
    }
  },
  props: {
    showDialog: { default: false },
    packageData: Object,
    id: Number,
    showDownload: Boolean
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    this.url = this.$url
  },
  methods: {
    onCopy(){
	this.$message('复制成功','success')
    },
    onError(){
	this.$message('复制失败','warning')
    },
    // 监听关闭
    closeDialog () {
      this.$emit('closeDialog')
    },
    currentChange (val) {
      this.$emit('updatePackageData', val)
    }
  },
  watch: {
    showDialog (val) {
      this.dialogTableVisible = val
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/childTable.css">
</style>

<style scoped>

.cpImg{
   width:20px !important;
   height:auto;
   vertical-align:middle;
   margin:0 5px 0 5px;
}
.noway{
   text-align:center;
}
.nowaybill{
   border:1px solid #f7d66f;
   border-radius: 4px;
   background-color:#fcf5ed;
   padding: 1px 6px;
   color: #fb9f1c;
   line-height: 1.3;
   text-align:center;
}

</style>