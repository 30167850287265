<template>
  <div class="payDialog">
    <el-dialog title="付款" width="40%" :visible.sync="dialogVisible" @close="closePayDialog">
      <div v-if="showSuccess" class="content">
        <div class="name"> 商品名称：<span>{{ name }}</span> </div>
        <div class="price"> 支付金额：<span>￥ {{ Number(price).toFixed(2) }}</span> </div>
        <div>
          <div class="payway-btn">
            <span>支付方式：</span>
            <div class="way">
              <div class="payway active">
                <img class="yue" src="~assets/icons/yue.png" /> <img class="dagou" src="~assets/icons/dagou.png" />
                <span>账户余额</span>
              </div>
            </div>
          </div>
          <div class="payway-info">
            <p>
              账户余额：￥<span>{{ money }}</span>元<span @click="toRecharge">充值></span>
            </p>
          </div>
          <div class="msg">{{ msg }}</div>
          <div class="zhu">注：支付宝单笔支付暂时关闭，请充值到余额支付</div>
          <br />
          <div class="paybtn" :disabled="flag">
            <el-button style="display: none" id="callBackBtn" @click.native="imports" ref="import" :data-list="dataList">v-trigger回填</el-button>
            <el-button type="danger" :disabled="disabled" class="payment scantopay" @click="payment" >{{ paymentText }} </el-button>
          </div>
        </div>
      </div>
      <!-- 付款成功显示 -->
      <div v-else class="content">付款成功</div>
    </el-dialog>
  </div>
</template>

<script>
import { orderPay } from 'network/api'
import { Loading } from 'element-ui'
export default {
  data () {
    return {
      flag: false,
      outerVisible: false,
      innerVisible: false, // 内外层
      isDisplay: false,
      dialogVisible: false,
      pri: '',
      mon: '',
      id: '',
      showSuccess: true,
      paymentText: '余额支付',
      disabled: false,
      dataList: '{}'
    }
  },
  directives: {
    // trigger: {
    //   inserted () {
    //     console.log('触发了', '66--------')
    //   }
    // }
  },
  props: {
    dialogShow: { default: false },
    name: null,
    price: null,
    money: null,
    orderId: null,
    msg: null,
    fromList: { type: Boolean, default: false }
  },
  methods: {
    changeDisplay () {
      this.isDisplay = !this.isDisplay
    },
    closePayDialog () {
      this.$emit('closePayDialog')
    },
    // 前往重置
    toRecharge () {
      this.$router.push('/account/mycenter')
    },
    imports () {
      this.$emit('imports')
      // this.$refs.import.$emit('click')
    },
    // 支付
    payment () {
      this.flag = true
      const loading = this.$loading({
        lock: true,
        text: '正在支付',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // setTimeout(() => { loading.close() }, 2000)
      // 判断余额不足
      var p = Number.parseFloat(this.pri)
      var m = Number.parseFloat(this.mon)
      if (p > m || Number.parseFloat(this.price) > Number.parseFloat(this.money)) {
        setTimeout(() => { this.flag = false }, 3000)
        // console.log(p, m)
        setTimeout(() => {
          loading.close()
          return this.$message({ message: '当前账户余额不足 请充值', type: 'warning', duration: 5000 })
        }, 2000)
        this.$router.push('/account/mycenter')
      }
      // 余额支付
      const key = localStorage.getItem('code')
      orderPay(this.id, key).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code == 0) {
          this.dialogVisible = false
          this.disabled = false
          loading.close()
          return this.$notify.warning({ title: res.msg, duration: 10000 })
        }
        if (res.code !== 1) {
          this.dialogVisible = false
          this.disabled = false
          this.$router.push('/account/mycenter')
          loading.close()
          return this.$notify.warning({ title: res.msg, duration: 10000 })
        }
        this.paymentText = '正在支付'
        this.disabled = true
        const that = this
        // 支付成功
        if (res.code === 1) {
          loading.close()
          this.$message.success({ message: '付款成功！' })
          this.dataList = JSON.stringify(res.data)
          // this.$refs.import.$el.click() // 触发一个按钮事件
          // this.$refs.import.$trigger('click')
          // this.imports()
          setTimeout(function () {
            const loadingInstance = Loading.service({
              fullscreen: true,
              text: '已完成支付正在跳转',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            document.getElementById('callBackBtn').click()
            setTimeout(function () {
              loadingInstance.close()
            }, 200)
          }, 100)
          // this.$refs.import.$el.click() // 触发一个按钮事件
          // this.$emit('formSubmit', JSON.stringify(this.dataList))
          this.paymentText = '余额支付'
          this.disabled = false
          // 从商城引用
          if (!this.fromList) {
            setTimeout(function () {
              that.$router.push('/orderAdmin/orderList')
            }, 500)
          } else {
            // 从列表引用
            setTimeout(function () {
              that.dialogVisible = false
            }, 300)
          }
        }
      })
    }
  },
  watch: {
    dialogShow (val) {
      this.dialogVisible = val
    },
    price (val) {
      this.pri = val
    },
    money (val) {
      this.mon = val
    },
    orderId (val) {
      this.id = val
    }
  }
}
</script>

<style scoped src="styles/components/payDialog.css"></style>

<style scoped lang="scss">
.payDialog .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}

.payDialog .el-button--danger {
  background-color: #f93244;
  border-color: #f93244;
}

.el-button {
  min-width: 150px;
}
::v-deep .content {
  padding: 0 20px 0 30px;
}
.msg{
  color:#7cad09;
  padding: 10px 0;
  font-size:16px;
}
</style>
